import React from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from '@/assets'
import "./styles.less"

const IndexPage = ({ history }) => {
  
  return (
    <div className="home-page">
      <div className="desc-container">
        <p className="title">
          <span style={{ color: '#10C29F' }}>Bull</span> vs <span style={{ color: '#FF5151' }}>Bear</span>: <br />
          The Ultimate Crypto <br />
          Betting Showdown
        </p>
        <p className="content">
        Place your bets, predict the market, and win big in this thrilling Bitcoin price prediction game.
        </p>
        <div className="button-container black-button" onClick={() => window.open('https://futureschain.org/')}>
          <img src={IMAGE.fchain} alt="" className="icon-l" />
          <p>
          Learn More About the Game <br />
          Environment: FuturesChain.
          </p>
          <img src={IMAGE.linkw} alt="" className="icon-r" />
        </div>
        <div className="button-container white-button" onClick={() => window.open('https://futures.cash/')}>
          <img src={IMAGE.fcash} alt="" className="icon-l" />
          <p>
          Access the Game DAPP <br />
          through FuturesCash. 
          </p>
          <img src={IMAGE.linkb} alt="" className="icon-r" />
        </div>
      </div>
      <img src={IMAGE.pcbg} alt="" className="pcbg" />
      <img src={IMAGE.mebg} alt="" className="mebg" />
    </div>
  )
}
export default withRouter(IndexPage)