import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Home, NotFound } from "@/page"
import "./App.less"

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
    </Router>
  )
}

export default App