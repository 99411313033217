import pcbg from './assets/pc-bg.png'
import mebg from './assets/mobile-bg.png'
import fcash from './assets/futures-cash.png'
import fchain from './assets/futures-chain.png'
import linkw from './assets/link-white.png'
import linkb from './assets/link-black.png'

const IMAGE = {
  pcbg, mebg, fcash, fchain, linkw, linkb
}

export default IMAGE